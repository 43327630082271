import { Container, Grid } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import { ButtonIcon } from "../components/atoms";
import { HeaderVista } from "../components/organism";
import { PRIVATE_ROUTES } from "../routes/name-path.routes";

export const RoutesNotFound = (props) => {
  return (
    <Routes>
      {props.children}
      <Route
        path="*"
        element={
          <Container component={"main"} maxWidth="lg">
            <Grid container spacing={2}>
              <HeaderVista
                start={<></>}
                titulo={"Pagina no encontrada."}
                end={
                  <>
                    <ButtonIcon size={"big"} variant={"contained"} to={PRIVATE_ROUTES.HOME} children={"Ir aL INICIO"} />
                  </>
                }
              />
            </Grid>
          </Container>
        }
      />
    </Routes>
  );
};
