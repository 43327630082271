/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback, useMemo, memo } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";

const SelectMultiple = memo((props) => {
  const [optionSelected, setOptionSelected] = useState([]);
  const [selectAlls, setSelectAlls] = useState(props.allSelected);

  const newOptionSelected = useMemo(() => {
    return props?.elegidos
      ?.map((elemento) => {
        const option = props.options.find((value) => elemento === value.value);
        return option ? option.label : null;
      })
      .filter((label) => label)
      .join(", ");
  }, [props.elegidos, props.options]);

  const handleToggle = useCallback(
    (option) => () => {
      const currentIndex = props?.elegidos?.indexOf(option.value);
      const newChecked = [...props?.elegidos];
      if (currentIndex === -1) {
        newChecked.push(option.value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      props.onChange(newChecked, props.name);
    },
    [props.elegidos, props.onChange, props.name]
  );

  useEffect(() => {
    setOptionSelected(newOptionSelected);
  }, [newOptionSelected]);

  useEffect(() => {
    if (props.optionAllSelects) {
      const allValues = props?.options?.map((option) => option.value);
      setOptionSelected(props.options.map((option) => option.label).join(", "));
      props.onChange(allValues, props.name);
    }
  }, [props.optionAllSelects]);

  const handleSelectAll = useCallback(() => {
    const allSelected = props?.elegidos?.length === props?.options?.length;
    if (!allSelected) {
      const allValues = props.options.map((option) => option.value);
      setOptionSelected(props.options.map((option) => option.label).join(", "));
      props.onChange(allValues, props.name);
    } else {
      setOptionSelected("");
      props.onChange([], props.name);
    }
    setSelectAlls(!allSelected);
  }, [props.elegidos, props.options, props.onChange]);

  const renderValue = useMemo(() => () => optionSelected, [optionSelected]);

  return (
    <TextField
      select
      sx={{
        color: "red",
        marginTop: "15px",
        maxHeight: "200px",
      }}
      label={props.label}
      value={props.elegidos}
      disabled={props.disabled}
      helperText={props.helperText}
      fullWidth
      required={props.required}
      type={props.type}
      SelectProps={{
        multiple: true,
        renderValue,
        MenuProps: {
          PaperProps: {
            style: {
              maxHeight: 300,
            },
          },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        },
      }}
    >
      <MenuItem onClick={() => handleSelectAll(selectAlls)}>
        <Checkbox checked={props?.elegidos?.length === props?.options?.length} />
        <ListItemText primary={props?.elegidos?.length === props?.options?.length ? "Deseleccionar todo" : "Seleccionar todo"} />
      </MenuItem>
      {props?.options?.map((option, index) => (
        <MenuItem key={index} value={option} onClick={handleToggle(option)}>
          <Checkbox checked={props?.elegidos && props?.elegidos.indexOf(option?.value) !== -1} />
          <ListItemText primary={option?.label} />
        </MenuItem>
      ))}
    </TextField>
  );
});

export default SelectMultiple;
