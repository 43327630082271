import { Container, TablePagination } from "@mui/material";
import * as React from "react";
import { useDispatch } from "react-redux";
import "../../assets/css/table.css";

const DataTable = React.memo(({ rows, columns, RowComponent, pagination, handlePagination, to, colores = {}, enablePaginate = true }) => {
  const data = React.useMemo(() => rows, [rows]);
  const dispatch = useDispatch();

  const renderTableHeader = () => (
    <tr style={{ height: "10%" }}>
      {columns.map((column, index) => (
        <th key={index} align={column?.align ?? "left"}>
          {column.headerName}
        </th>
      ))}
    </tr>
  );

  const handleChangePage = (event, newPage) => {
    dispatch(
      handlePagination({
        page: newPage + 1,
      })
    );
  };

  function defaultLabelDisplayedRows({ from, to, count }) {
    return `${from}–${to} de ${count !== -1 ? count : `mas de ${to}`}`;
  }

  const handleChangeRowsPerPage = (event) => {
    dispatch(
      handlePagination({
        page: 0,
        limit: event.target.value,
      })
    );
  };

  const renderTableBody = () => {
    if (data?.length > 0) {
      return data?.map((row) => <RowComponent key={row._id} row={row} to={`${to}?id=${row._id}`} colores={colores} />);
    }

    return (
      <tr>
        <td colSpan="12">No se encontraron registros</td>
      </tr>
    );
  };

  return (
    <Container maxWidth="lg">
      <div className="tableContainer">
        <table className="formato1" cellSpacing="0" cellPadding="0">
          <thead>{renderTableHeader()}</thead>
          <tbody>{renderTableBody()}</tbody>
        </table>
      </div>
      {enablePaginate && (
        <TablePagination
          component="div"
          count={pagination.totalDocs}
          page={pagination.page - 1}
          rowsPerPage={pagination.limit}
          labelRowsPerPage="Registros por pagina"
          labelDisplayedRows={defaultLabelDisplayedRows}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Container>
  );
});

export default DataTable;
