import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Autocomplete } from "@mui/material";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";

const AutocompleteList = memo(({ slice, handleChange, name, response, defaultValue, value }) => {
  const debounceTimeout = useRef(null);
  const [localValue, setLocalValue] = useState({ label: "", key: "" });
  const [optionList, setOptionList] = useState([]);
  const handleInputChange = useCallback(
    (newInputValue) => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }

      if (newInputValue) {
        debounceTimeout.current = setTimeout(() => {
          slice({ page: 0, limit: 15, name: newInputValue });
        }, 1000);
      } else {
        setOptionList([]);
      }
    },
    [slice]
  );

  const handleOptionChange = (newValue) => {
    if (!newValue || !newValue.label) {
      setLocalValue(defaultValue);
      handleChange(defaultValue, name);
    } else {
      setLocalValue(newValue);
      handleChange(newValue, name);
    }
  };

  const formattedOptions = useMemo(() => {
    return response.isSuccess ? response.data.data.docs.map((el) => ({ label: el.nombre, key: el.codigo })) : [];
  }, [response.isSuccess, response.data]);

  useEffect(() => {
    setOptionList(formattedOptions);
  }, [formattedOptions]);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  return (
    <Autocomplete
      sx={{ width: 300 }}
      disableListWrap
      value={localValue}
      defaultValue={defaultValue}
      options={optionList}
      isOptionEqualToValue={(option, localValue) => option.key === localValue.key}
      onChange={(event, newValue) => {
        handleOptionChange(newValue);
      }}
      onInputChange={(event, newInputValue, reason) => {
        if (reason === "input") {
          handleInputChange(newInputValue);
        }
      }}
      noOptionsText={!optionList.length ? "No hay opciones" : ""}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            value={localValue?.label}
            variant="standard"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {response.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        );
      }}
      renderOption={(props, option) => (
        <li {...props} key={option.key}>
          {option.label}
        </li>
      )}
    />
  );
});

export default AutocompleteList;
